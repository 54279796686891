export const events = {
    subscribeTagsButtonTitle: '6R8Woo0XiB',
    replayToComment: 'Fxq31RbHW1',
    commentIsPosted: 'Opj19MiAC8',
    openMobileNavbar: 'oi86FumUnw',
    openLoginModal: '3q8W6FumUn',
}

export const storage = {
    selectedSubscriptionTags: 'AZ8tfW8ewE',
    cachedComment: 'ymHG7wkb4E',
    allowCommentAt: 'dRWEP3sYjO',
    scrollPosition: 'mm20dfjal5n',
    toastMessageAfterRefresh: 'b10fpqc6d01',
    toastRedirectAfterRefresh: 'd03jslwh26dh9',
}
